<template>
  <div>
    <div align="center" class="my-5" v-if="carrello_vuoto === ''">
      <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      variant="primary"
      />
    </div>

    <section id="dashboard-ecommerce" class="mb-4" v-if="carrello_vuoto !== ''">
      <b-row>
        <b-col md="9">
          <h1 class="mb-0">{{ data.dati_fiscali.rag_soc }}</h1>
          <p>utente: {{ this.userData.fullName }}</p>
        </b-col>
        <b-col md="3">
          <b-button
          v-if="data.articoli_carrello > 0"
          variant="primary"
          :to="{ name: 'catalogo-carrello' }"
          block
          >Concludi Ordine
          </b-button>
          <b-button
          v-if="data.articoli_carrello == 0"
          variant="primary"
          :to="{ name: 'catalogo-articoli' }"
          block
          >Articoli
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <div class="bg-light-primary rounded-top text-center">
              <b-img
                :src="require('@/assets/images/illustration/email.svg')"
                alt="anagrafica azienda"
                height="170"
              />
            </div>
            <b-card-body>
              <h3 class="mb-2">Informazioni Fiscali</h3>
              <!-- metting header -->
              <div class="meetup-header d-flex align-items-top">
                <div class="meetup-day">
                  <span class="text-muted">codice cliente:</span><br />
                  <h5 class="mb-0">
                    {{ data.dati_fiscali.codice }}
                  </h5>
                </div>
                <div class="my-auto">
                  <h3 class="mb-25">
                    {{ data.dati_fiscali.rag_soc }}
                  </h3>
                  <b-card-text class="mb-25">
                    p.iva: {{ data.dati_fiscali.piva }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    sdi: {{ data.dati_fiscali.sdi }}
                  </b-card-text>
                </div>
              </div>
              
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.dati_fiscali.indirizzo }}<br />
                    {{ data.dati_fiscali.cap }} - {{ data.dati_fiscali.localita }} ({{ data.dati_fiscali.provincia }}) - {{ data.dati_fiscali.paese }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.dati_fiscali.telefono }}</h6>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.dati_fiscali.email }}</h6>
                </b-media-body>
              </b-media>

            </b-card-body>

          </b-card>
        </b-col>
        <b-col md="6">
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <div class="bg-light-primary rounded-top text-center">
              <b-img
                :src="require('@/assets/images/illustration/sales.svg')"
                alt="spedizioni"
                height="170"
              />
            </div>
            <b-card-body>
              <b-card-title>Indirizzi di spedizione salvati</b-card-title>

              <b-alert variant="info" align="mb-4" show>
                <h5 class="alert-heading">Al momento dell'ordine, in caso di necessità, è possibile specificare un indirizzo di spedizione diverso dagli indirizzi precedentemente salvati</h5>
              </b-alert>

              <div class="business-card">
                <div class="business-items">
                  <div
                    v-for="businessItem in data.spedizioni"
                    :key="businessItem.id"
                    class="business-item"
                  >
                    <div class="d-flex align-items-center justify-content-between">
                      <div>
                        <h5>{{ businessItem.rag_soc }}</h5>
                        {{ businessItem.indirizzo }}<br />
                        {{ businessItem.cap }} - {{ businessItem.localita }} ({{ businessItem.provincia }}) - {{ businessItem.paese }}
                      </div>
                      <b-badge variant="secondary">
                        {{ businessItem.codice_spe }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </div>

            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

    </section>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BCardTitle, BSpinner, BImg, BCardBody, BMedia, BMediaAside, BMediaBody, 
BAvatar, BAlert, BBadge, BButton } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BSpinner,
    BImg,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BAlert,
    BBadge,
    BButton,
  },
  data() {
    return {
      data: {
        dati_fiscali: { },
        spedizioni: { }
      },
      userData: {},
      carrello_vuoto: '',
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    //console.dir(this.userData)
    //console.log('richiedi id in Anagrafica => '+this.userData.id_azienda)

    this.$http.get('v2/azienda/infoazienda/'+this.userData.id_azienda)
      .then(response => {
        this.data = response.data

        if(response.data.articoli_carrello == 0){
          //carrello vuoto
          this.carrello_vuoto = true;
        } else {
          //almeno un articolo c'è => procediamo con l'ordine
          this.carrello_vuoto = false;
          
        }

        console.log(response.data);

      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>